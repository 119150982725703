* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    border: medium none;
    margin: 0;
    padding: 0;

}

/* Chrome, Safari, Edge, Opera hide number field arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

:focus {
    outline: none !important;
}

img,
fieldset {
    border: 0 none;
}

a {
    border: none;
    outline: none;
}

input[type="submit"],
select {
    -webkit-appearance: none;
}

img {
    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    vertical-align: middle;
}

a:hover {
    text-decoration: none;
    color: red;
}

.clear {
    clear: both;
}

ul::after {
    clear: both;
    content: "";
    display: block;
}

ul {
    list-style: none;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 27px;
    color: #474646;
    -webkit-font-smoothing: antialiased;
    font-family: 'Raleway' !important;

}

.header {
    background: #1A003D;
}

.header .dropdown-menu.show a {
    font-size: 15px;
    padding: 5px 15px;
}

.btn-height {
    height: 41px;
}

.header .navbar-light .navbar-toggler {
    border: none;
}

.header .navbar-light .navbar-toggler-icon {
    background-image: url("../images/header-menu.svg");
}

.connect-wallet-btn .nav-link {
    color: #1a003d !important;
    border-radius: 10px;
    background: #ffff;
    font-size: 15px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: 100%;
    height: 44px;
    border: 2px solid #1a003d;
}
.header .navbar-light .navbar-nav .nav-link {
    color: #fff;
    margin: 0 8px;
    font-weight: 400;
    font-size: 14px;
    position: relative;
}
.new-badge {
    position: absolute;
    background: #ff0000;
    display: inline-block;
    border-radius: 0;
    padding: 0px 12px 0 5px;
    line-height: 17px;
    right: -8px;
    font-size: 11px;
    top: -6px;
    clip-path: polygon(100% 0%, 84% 50%, 100% 100%, 0 100%, 0 50%, 0 0);
}

.header a.active.nav-link {
    font-weight: 700 !important;
}

.connect-wallet-btn.diconnect-btn .nav-link {
    color: #FFF !important;
    background: #1a003d;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    border: 2px solid #fff !important;
    margin-left: 0 !important;
}

.banner-area {
    background-image: url('../images/main-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 130px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background-position: bottom center;
    position: relative;
}

.banner-area-content {
    text-align: center;
    position: relative;
    margin-top: 60px;
}

.banner-area-content img {
    position: absolute;
    right: -35px;
    top: -30px;
}

.banner-area-content h1 {
    font-size: 52px;
    color: #1a003d;
    font-weight: 800;
}

.banner-area-content p {
    font-size: 42px;
    text-transform: capitalize;
    color: #1a003d;
    font-weight: 500;
    margin: 15px 0 30px;
}

.banner-area-content button {
    color: #fff !important;
    border-radius: 10px;
    background: var(--dark-blue, #1A003D) !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    font-size: 15px !important;
    font-weight: 500;
    padding: 8px 15px;
}

.common-padding {
    padding: 50px 0;
}

.prediction-market-area h2 {
    text-align: left;
    font-size: 50px;
    color: #1a003d;
    font-weight: 700;
}

.prediction-box {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    background: var(--white, #FFF);
    box-shadow: -9.131px 17.433px 39.846px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

.prediction-market-inner {
    display: flex;

}

span.error {
    font-size: 14px;
    color: red;
}

.prediction-market-left {
    margin-right: 10px;
}

.option-swiper .swiper {
    padding: 0 17px;
}

.prediction-market-left img {
    width: 65px;
    min-width: 65px;
    height: 65px;
    min-height: 65px;
    object-fit: cover;
    border-radius: 4px;
}

.prediction-market-right span {
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 10px;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin: -10px 0 10px auto;
}

.prediction-market-right h5 {
    color: #1a003d;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 30px;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
}

.prediction-market-right p {
    font-size: 14px;
    color: #1a003d;
    font-weight: 500;
    line-height: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
}

.prediction-market-right button {
    color: #fff !important;
    border-radius: 10px;
    background: var(--dark-blue, #1A003D) !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    font-size: 15px !important;
    font-weight: 500;
    padding: 5px 25px;
    display: flex;
    margin: 8px 0 0 auto;
}

button.viw-more-btn {
    color: #fff !important;
    border-radius: 10px;
    background: var(--dark-blue, #1A003D) !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    font-size: 17px !important;
    font-weight: 500;
    padding: 8px 35px;
    display: flex;
    margin: 30px auto 0;
    width: fit-content;
}

button.contact-btn {
    border-radius: 10px;
    background: var(--sky-blue, #29B7F6) !important;
    color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    padding: 9px 40px;
    display: flex;
    margin: 0 auto 40px;
}

.footer {
    background: #F8F9F9;
    padding: 50px 0;
}

.footer-content p {
    color: #616161;
    font-size: 15px;
    font-weight: 500;
}

.social-icons ul {
    display: flex;
    align-items: center;
    justify-content: end;
}

.footer-content {
    text-align: end;
}

.social-icons li i {
    color: #0D65DB;
    font-size: 23px;
    margin: 0 20px 0 0;
}

.social-icons h6 {
    color: #3A3C3F;
    font-size: 15px;
    margin-bottom: 12px;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: unset !important;
}

.bottom-footer p {
    color: #fff;
    padding: 10px 0;
    font-size: 14px;
    text-align: center;
}

.bottom-footer {
    background: #1a003d;
}

span.min-clr {
    background: #FE3E75;
    color: #fff;
}

.prdiction-box-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.prdiction-box-bottom p span {
    color: #A550D8;
}

.prdiction-box-bottom p {
    color: #1a003d;
    font-weight: 600;
    margin-bottom: 7px;
}

button.extra-time-btn {
    background: #ff7a34 !important;
    border: none;
    margin-right: 4px;
    padding: 4px 14px !important;
}

.prdiction-box-bottom button, .prdiction-box-bottom button:hover {
    color: #fff !important;
    border-radius: 10px;
    background: var(--dark-blue, #1A003D);
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    font-size: 13px !important;
    font-weight: 500;
    padding: 4px 12px;
    text-transform: capitalize;
}
button.extra-time-btnbet-more-btn {
    color: #fff !important;
    border-radius: 10px;
    background: #ae62dc;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    font-size: 13px !important;
    font-weight: 500;
    padding: 4px 12px;
    text-transform: capitalize;
    border: none;
    margin-right: 4px;
}
.prdiction-box-bottom .btn-check:checked+.btn,
.prdiction-box-bottom .btn.active,
.prdiction-box-bottom .btn.show,
.prdiction-box-bottom .btn:first-child:active,
:not(.btn-check)+.btn:active {
    background: var(--dark-blue, #1A003D);
}

.prediction-market-right ul span {
    margin: 0;
}

.prediction-market-right ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1px 8px;
}

.prediction-market-right ul li i {
    margin-right: 6px;
}

.prediction-market-right ul li {
    width: 50%;
    color: #1a003d;
    font-size: 13px;
    font-weight: 600;
}

.prediction-market-right ul li p span {
    color: #a550d8;
    font-weight: 600;
    padding: 0;
    font-size: 12px;
}

.prediction-market-right ul li p {
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
}

.filter-area-top {
    align-items: center;
    justify-content: end;
    margin: 0 0 0 7px;
}

.explore-top-filter {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
}

.bg-vector {
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: -10px;
    z-index: 99;
    left: 98px;
}

.prediction-box-outer {
    position: relative;
    height: 100%;
}

.prediction-market-right {
    margin-top: -25px;
    width: 80%;
}

.filter-area-top label,
.board-filters label {
    margin-bottom: 0;
    color: #1a003c;
    font-weight: 700;
    margin-right: 7px;
    font-size: 15px;
}

.board-filters label {
    margin-bottom: 0;
    color: #fff;
    font-weight: 700;
    margin-right: 7px;
    font-size: 15px;
}

.board-filters .mb-2 {
    display: flex;
    align-items: center;
}

.filter-area-top .css-13cymwt-control {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: var(--white, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
    width: 120px;
    cursor: pointer;
}

.board-filters .css-13cymwt-control {
    background: #1d0042;
    border-radius: 10px;
    border: 1px solid rgb(255 255 255 / 50%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
    width: 160px;
    cursor: pointer;
}

.board-filters .css-b62m3t-container {
    width: 160px;
}

.board-filters .css-tj5bde-Svg {
    display: inline-block;
    fill: #fff;
}

.board-filters .css-1dimb5e-singleValue {
    color: hsl(0deg 0% 100%);
}

.board-filters .css-qbdosj-Input {
    color: hsl(0deg 0% 100%);

}

.audit-request-box.leaderboard-inner {
    background: #1d0043;
    padding: 10px;
    border-radius: 20px;
}

.audit-request-box.leaderboard-inner.referral-board tr th,
.audit-request-box.leaderboard-inner.referral-board tr td {
    min-width: 340px !important;
}

.referral-board .css-13cymwt-control,
.referral-board .css-b62m3t-container {
    width: 170px;
}

.css-b62m3t-container {
    width: 120px;
}

.board-filters {
    display: flex;
    justify-content: end;
}

.filter-area-top .css-tsipsp-control {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: var(--white, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14) !important;
    width: 120px;
}

.filter-area-top .css-tsipsp-control:hover {
    border-color: rgba(0, 0, 0, 0.50) !important;
}

.bg-vector p {
    position: absolute;
    top: 37%;
    left: 50%;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

span.days-clr {
    background: #29B7F6;
    color: #fff;
}

span.purple-clr {
    background: #A550D8;
    color: #fff;
}

.prediction-detail-image img {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.prediction-detail-left {
    display: flex;

}

.prediction-detail-image {
    margin-right: 25px;
    text-align: right;
}

.prediction-detail-left-content h5 {
    font-size: 27px;
    color: #1a003d;
    font-weight: 700;
}

.prediction-detail-left-content p {
    color: #1a003d;
    font-size: 16px;
    font-weight: 500;
    max-height: 250px;
    overflow: auto;
}

.prediction-detail-left-content p::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
}

.prediction-detail-left-content p::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.prediction-detail-left-content p::-webkit-scrollbar-thumb {
    background-color: #1A003D;
}

.prediction-detail-left-content h5 {
    font-size: 27px;
    color: #1a003d;
    font-weight: 700;
    margin: 0 0 5px;
}

.prediction-detail-left-content h6 {
    color: #1a003d;
    font-weight: 700;
    font-size: 20px;
    margin: 25px 0 10px;
}

.prediction-detail-left-content .form-label {
    font-size: 15px;
    color: #0E0E0E;
    font-weight: 600;
    margin-bottom: 3px;
}

.prediction-detail-left-content input#formBasicPassword {
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    border: none !important;
    padding: 13px;
    max-width: 200px;
    width: 100%;
}

.prediction-detail-left-content ul {
    list-style: inside;
}

.radio-btn-option .form-check {
    margin: 0 15px;
}

.radio-btn-option {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.radio-btn-option .form-check-input {
    border: 2px solid #A093AB;
    display: none;
}

.radio-btn-option label {
    color: #A093AB;
    font-weight: 600;
    font-size: 15px;
}

.radio-btn-option .form-check-input:checked {
    background-color: #A550D8;
    border-color: #A550D8 !important;
    border: 2ps;
}

.prediction-detail-left-content button {
    border-radius: 10px;
    background: var(--dark-blue, #1A003D) !important;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    color: #fff !important;
    font-weight: 600;
    width: 100%;
    max-width: 300px;
    padding: 10px 0;
    font-size: 18px;
    margin-bottom: 20px;
}

.congrats-popup-area {
    margin-left: 15px;
}

.prediction-detail-right {
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    background: #fff;
    padding: 20px 30px 20px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.common-result {
    position: absolute !important;
    width: -moz-fit-content;
    width: fit-content !important;
    top: 17px !important;
    right: 50px !important;
    z-index: 99 !important;
}

.prediction-time-top-area p {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.prediction-time-top-area {
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    top: 17px;
    left: 80px;
    z-index: 9;
}

.prediction-detail-right-top-heading {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 7px 0 10px 0;
}

.prediction-detail-right-top-heading h6 {
    font-size: 22px;
    color: #1a003d;
    font-weight: 700;
}

.betting-area {
    background: #FE3E75;
    color: #fff;
    padding: 0 7px;
    border-radius: 8px;
    line-height: 25px;
    text-align: center;
}

.betting-area p {
    font-size: 12px;
}

span.betting-text {
    margin-right: 6px;
    font-weight: 600;
}

/* .prediction-detail-right-top-heading p {
    background: #FE3E75;
    font-size: 14px;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    font-weight: 500;
} */
.prediction-detail-right .progress-bar {
    background-color: #A550D8;
}

.prediction-detail-right .progress {
    background: #F5EDFA;
    border-radius: 20px;
}

.progress-bar-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 0;
}

.progess-bar-info-left h6 span,
.progess-bar-info-right h6 span {
    font-size: 13px;
}

.progess-bar-info-left h6 {
    color: #a550d8;
    font-size: 18px;
    font-weight: 600;
}

.progess-bar-info-right h6 {
    color: #1A003D;
    font-size: 18px;
    font-weight: 600;
}

p.progress-total span {
    color: #a550d8;
    font-weight: 500;
}

p.progress-total {
    text-align: center;
    color: #1a003d;
    font-weight: 700;
}

.betting-window h6 {
    font-size: 17px;
    color: #1a003d;
    font-weight: 600;
    margin-bottom: 8px;
}

.betting-window {
    margin: 10px 0 0;
}

.betting-window-content {
    border-radius: 10px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    padding: 12px;
}

.start-end-date p span {
    font-size: 11px;
}

.start-end-date p {
    color: #FE3E75;
    font-size: 15px;
    font-weight: 600;
}

.start-end-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.start-date-info p,
.end-date-info p {
    color: #494040;
    font-weight: 500;
    font-size: 13px;
}

hr {
    margin: 8px 0 !important;
    opacity: 0.2 !important;
}

.leader-board {
    margin: 30px 0 0;
}

.leader-board-top h6 {
    font-size: 17px;
    color: #1a003d;
    font-weight: 600;
}

.leader-board-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.leader-board-top p {
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
    color: #fe3e75;
}

.leader-board-content p {
    font-size: 15px;
    color: #A550D8;
    font-weight: 600;
}

.leader-board-content h5 {
    font-size: 15px;
    color: #1A003D;
    font-weight: 500;
}

.leader-board-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 0;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.8;
}

.connect-wallet-box .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.connect-wallet-box .modal-body {
    text-align: center;
    padding-top: 0;
}

button.btn-close {
    opacity: unset;
}

.connect-wallet-box .modal-content {
    border-radius: 30px;
}

.modal-body p {
    color: #1a003d;
    font-size: 16px;
    font-weight: 500;
}

.connect-wallet-box .modal-body h3 {
    color: #1A003D;
    font-size: 30px;
    font-weight: 800;
    margin: 20px 0 5px;
}

.connect-wallet-box .modal-footer button {
    border-radius: 7.471px;
    background: var(--dark-blue, #1A003D);
    box-shadow: 0px 3.321px 14.942px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 40px;
}

.connect-wallet-box .modal-footer {
    justify-content: center;
    padding: 0 10px 30px;
    border-top: none;
}

.btn-close:focus {
    box-shadow: unset !important;
}

img.predicted-check {
    width: 120px;
}

.predict-payout-area {
    border-radius: 8px;
    border: 1px solid rgba(26, 0, 61, 0.51);
    background: #E3E3E3;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 34px;
}

.predict-payout-area p span {
    color: #FE3E75;
    font-size: 16px;
    font-weight: 700;
    margin-left: 6px;
}

.predict-payout-area p {
    font-size: 15px;
    color: #1a003d;
    font-weight: 600;
}

.predict-payout-area i {
    color: rgba(0, 0, 0, 0.60);
    font-size: 19px;
}

.predict-payout-area button {
    background: none !important;
    width: unset;
    border: none;
    padding: 0;
    margin-bottom: 0;
}

.connect-wallet-box .modal-body img {
    width: 140px;
}

.congratulation-area {
    border-radius: 20px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    margin-top: 40px;
    padding: 0 35px 30px;
    margin-bottom: 20px;
}

.congratulation-bg-area {
    background-image: url("../images/congratulation-bg.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    top: -20px;
}

.congratulation-content-area {
    text-align: center;
}

.congratulation-content-area p span {
    font-weight: 700;
    font-size: 18px;
}

.congratulation-content-area p {
    color: #fff;
    line-height: 22px;
}

.congratulation-content-area h5 {
    margin: 0;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 6px;
}

.congratulation-bg-area img {
    width: 90px;
    min-width: 90px;
}

.invested-area {
    display: flex;
    justify-content: space-between;
}

.invested-area h5 {
    margin: 0;
    font-size: 16px !important;
    font-weight: 500;
}

.invested-amount-area p {
    color: #A550D8;
    font-weight: 600;
    font-size: 16px;
}

.invested-amount-area span {
    color: #FE3E75;
    font-weight: 600;
}

p.won-price {
    color: #1a003d;
}

.congratulation-area h6 {
    margin: 0 0 15px 0;
    font-size: 18px;
}

.prediction-lost-area {
    background: #fff;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
}

.prediction-lost-content h5 {
    margin: 0;
    color: #fe3e75;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 6px;
}

.prediction-lost-content p {
    color: #fe3e75;
    line-height: 22px;
    font-weight: 600;
}

.prediction-lost-content {
    text-align: center;
}

.prediction-lost-area img {
    width: 70px;
    min-width: 70px;
}

.loader {
    border: 6px solid #1a003d5c;
    border-radius: 50%;
    border-top: 6px solid #1a003d;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.step-area {
    display: flex;
    align-items: self-start;
    margin: 30px 60px;
}


.steps-left-area {
    margin-right: 15px;
}

.steps-content h6 {
    font-size: 20px;
    color: #1a003d;
    font-weight: 700;
}

.steps-content {
    text-align: left;
}

.steps-left-area h2 {
    background: #1a003d;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 550%;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    min-width: 40px;
    min-height: 40px;
}

.prediction-detail-left-content {
    padding: 0 20px 0 0;
}

.navbar-toggler:focus {
    box-shadow: unset;
}

.leader-board-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.leader-board-content-area {
    height: 100%;
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
}

.leader-board-content-area::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
}

.leader-board-content-area::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.leader-board-content-area::-webkit-scrollbar-thumb {
    background-color: #1A003D;
}

.main-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background: #0000005e;
    width: 100%;
}

.prediction-detail-portion {
    min-height: 650px;
}

.bet-amount-area {
    max-width: 200px;
    width: 100%;
}

.comm-text-area {
    display: flex;
    justify-content: space-between;
    margin-top: 1px;
}

.comm-text-area p {
    color: #B0ACB4;
    font-size: 15px;
}

.left-spacing-area {
    margin-left: 15px;
}

.no-data-area p {
    color: #000;
    font-weight: 700;
    margin-top: 10px;
}

button.btn-close {
    padding: 12px !important;
}

.no-data-area.main-no-data img {
    width: 60px;
}

.no-data-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
}

.css-d7l1ni-option {
    cursor: pointer !important;
}

.coming-soon {
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.coming-soon-content p {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.coming-soon-content h2 {
    color: #1a003d;
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 7px;
}

.coming-soon-content button {
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    background: #1a003d;
    color: #fff;
    margin: 30px 0 0 0;
}

.swal-button {
    background-color: #1a003d !important;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px;
    margin: 0;
    cursor: pointer;
}

.swal-title {
    color: #1a003d;
    font-weight: 800;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}

.swal-text {
    font-size: 16px;
    position: relative;
    float: none;
    line-height: normal;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    font-weight: 400;
    color: #000;
    max-width: calc(100% - 20px);
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.swal-modal {
    border-radius: 30px;
}

.swal-footer {
    text-align: center;
    padding-top: 13px;
    margin-top: 13px;
    padding: 13px 16px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.disable.form-control {
    background: #a1a1a145 !important;
}

.follow-steps-popup.modal.show .modal-content {
    width: 80%;
    border-radius: 25px;
}

.follow-steps-popup.modal .modal-body {
    text-align: center;
    padding-top: 20px;
}

.follow-steps-popup.modal .modal-dialog {
    justify-content: center;
}

.back-top-btn button i {
    font-size: 19px;
    margin-right: 3px;
}

.back-top-btn button {
    background: #1a003d !important;
    color: #fff !important;
    font-weight: 600;
    padding: 7px 25px;
    border-radius: 10px;
}

.detail-top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.detail-top-area a {
    color: #1a003d;
    font-weight: 700;

}

.detail-top-area a {
    color: #fff;
    font-weight: 600;
    background: #1a003d;
    text-decoration: unset;
    padding: 11px 15px;
    border-radius: 10px;
}

.audit-request-box .table-responsive-sm {
    /* padding: 12px 21px; */
    border-radius: 20px;
    overflow: auto;
    box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
    color: #fff;
    /* background: #1A003D;
    border: 1px solid #1A003D; */
    min-height: 450px;
}

.heading-top-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-area-heading h2 {
    color: #1A003D;
    font-size: 33px;
    font-weight: 800;
}

.search-area {
    position: relative;
}

.search-area label {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.search-area .fa {
    position: absolute;
    top: 35px;
    right: 20px;
    color: #000000;
}

.leaderboard-info-top h6 {
    color: #fc3c74;
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 10px;
}

.top-leaderboard-right h2 {
    color: #fff !important;
    margin-bottom: 8px !important;
    font-weight: 800 !important;
}

.leaderboard-info-top p {
    color: #1a003d;
    font-weight: 700;
    margin-bottom: 5px;
}

.leaderboard-info-top ul li {
    list-style: disc;
    margin: 0 21px;
    font-size: 15px;
    color: #1d0043;
    font-weight: 500;
}

.leaderboard-info-top ul {
    display: flex;
    flex-wrap: wrap;
}

.transaction-management-area {
    padding: 30px 0;
    min-height: 650px;
}

.audit-request-box.leaderboard-inner tr th,
.audit-request-box.leaderboard-inner tr td {
    min-width: 200px !important;
}

.availabilit-section {
    overflow: auto;
}

.connect-wallet-btn .dropdown button {
    background: #ffff !important;
    border: none;
    color: #1a003d !important;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 15px;
    border-radius: 10px;
}

.connect-wallet-btn .dropdown-menu.show a {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 5px 13px;
    font-weight: 600;
}

.connect-wallet-btn .dropdown-menu.show {
    width: fit-content;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #1a003d;
    text-decoration: none;
    background-color: #1a003d1c;
}

.connect-wallet-btn .dropdown-menu.show a img {
    width: 20px;
    min-width: 20px;
    margin-right: 4px;
}

.inner-contact-form {
    padding: 30px;
    border-radius: 20px;
    box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
    color: #fff;
}

.contact-form {
    margin: 80px 0;
}

.inner-contact-form h2 {
    color: #1a003d;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 30px;
}

.inner-contact-form label {
    color: #1a003d;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1px;
}

.inner-contact-form .form-control {
    border: none;
    padding: 13px;
    background: #efefefad;
    width: 100%;
    height: 50px;
}

.wallet-balance p img {
    width: 19px;
    margin-right: 2px;
}

.react-tel-input .flag-dropdown {
    border: 1px solid #cacaca73;
}

.phone-area input.form-control {
    padding-left: 50px;
}

.react-tel-input .country-list .country {
    color: #000;
}

.inner-contact-form .form-control::placeholder {
    font-size: 14px;
}

.form-control:focus {
    box-shadow: unset !important;
}

.inner-contact-form button {
    background: #1a003d !important;
    border: none;
    font-weight: 600;
    padding: 10px 50px;
    display: flex;
    margin: 40px auto 0;
    border-radius: 10px;
}

.table-no-data {
    min-height: 400px;
}

ul.pagination {
    justify-content: end;
    margin-top: 12px;
}

.pagination .disabled {
    opacity: 0.5;
}

.pagination li {
    padding: 14px;
    color: #fff;
}

ul.pagination li a {
    color: #1a003d;
    font-weight: 600;
}

.pagination li {
    padding: 14px;
    color: #fff;
}

.pagination .active a {
    color: #fff !important;
    border: 1px solid;
    padding: 10px;
    background: #1a003d;
}

.wallet-balance button {
    background: #1a003d;
    padding: 8px 20px;
    color: #fff !important;
    font-weight: 600;
    border: 2px solid #fff !important;
    border-radius: 10px;
    margin: 0 0 0 10px;
}

.wallet-balance button img {
    width: 23px;
    margin-right: 6px;
}

.audit-request-box a {
    color: #1a003d !important;
}

.audit-request-box.leaderboard-inner a {
    color: #fff !important;
}

p.correct-answer span {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

p.correct-answer {
    color: #a550d8;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
    line-height: 19px;
    padding: 0 22px;
}

.about-area-content {
    color: #000;
}

.about-area-content h2 {
    font-size: 40px;
    font-weight: 800;
    color: #1a003d;
    margin-bottom: 15px;
}

.about-area-content p {
    font-weight: 500;
    padding: 5px 0;
    color: gray;
}

.about-area-content ul li {
    font-weight: 500;
    padding: 5px 0;
    list-style: disc;
    color: gray;
}

.about-area-content h3 {
    font-weight: 700;
    font-size: 24px;
    padding: 25px 0 5px 0;
}

.about-area-content h5 {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 0 5px 0;
}

.about-area-content ul {
    padding-left: 40px;
}

.about-area-content p b {
    color: #000;
    margin-right: 8px;
}

.about-area-content p a,
.about-area-content p span {
    color: #fe3e75;
    font-weight: 800;
}

.about-area-content img {
    display: flex;
    margin: 30px auto;
}

.leader-board-content p span {
    color: #fe3e75;
    font-weight: 700;
    margin-left: 2px;
}

.audit-request-box tr th {
    white-space: nowrap;
}

.header .navbar-brand img {
    width: 50px;
    min-width: 50px;
}

.social-icons ul li a img {
    width: 21px;
    margin: -6px 20px 0 0;
}

.about-area-content a,
.terms-services-area a {
    color: #fe3e75;
    font-weight: 700;
    font-size: 16px;
}

.footer-left-area ul li a {
    color: #1a003d;
    font-size: 15px;
    text-decoration: unset;
    font-weight: 600;
}

.footer-left-area ul {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.footer-left-area ul li {
    list-style: disc;
    margin: 0 40px 0 0;
}

.terms-services-area h2 {
    font-size: 30px;
    font-weight: 800;
    color: #1a003d;
    margin-bottom: 15px;
}

.terms-services-area p {
    color: gray;
    font-weight: 500;
    font-size: 15px;
    padding: 4px 0;
}

.terms-services-area h6 {
    color: #000;
    margin: 30px 0 2px 0;
    font-weight: 700;
    font-size: 19px;
}

p.prediction-only-view {
    color: #fe3e75;
    font-weight: 700;
    font-style: italic;
}

.transaction-top-heading h2 {
    font-size: 28px;
    /* margin-bottom: 20px; */
    color: #1a003d;
    font-weight: 900;
}

.leaderboard-card p {
    font-size: 18px;
    color: #1a003d;
    font-weight: 600;
    margin-bottom: 6px;
    word-break: break-all;
}

.leaderboard-card h4 {
    font-size: 40px;
    font-weight: 800;
    color: #fb3c74;
}

.leaderboard-card {
    background-image: url("../images/leader-board-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px;
    border-radius: 20px;
    height: 100%;
}

.transaction-top-heading h2 img {
    width: 30px;
}

tr.your-leaderboard {
    background: #1a003d12;
    border-radius: 10px;
}

.audit-request-box.leaderboard-inner .table-responsive-sm {
    padding: 12px;
    background: #1d0043;
}

.audit-request-box.leaderboard-inner tr th,
.audit-request-box.leaderboard-inner tr td {
    color: #ffffff;
    font-size: 14px;
    padding: 10px 30px;
    background-color: transparent !important;
}

.audit-request-box .table-responsive-sm thead tr th {
    color: #fff;
    background: #1d0043;
}

.audit-request-box tr td {
    font-size: 14px;
}

table.UserListTable.table {
    overflow: hidden;
    margin-bottom: 0;
}

.transaction-top-heading.leader-board-info-bottom {
    background: #1D0043;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0 20px;
    margin-bottom: 5px;
}

/* .transaction-top-heading {
    background: #1D0043;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0 20px;
    margin-bottom: 20px;
} */

.top-leaderboard-left img {
    width: 200px;
    max-width: 200px;
    margin-top: -50px;
}

.top-leaderboard-right {
    color: #fff;
    text-align: left;
}

tr.first-winner {
    background: linear-gradient(90deg, #ffe59c70 0%, rgba(255, 255, 255, 0.00) 91.33%);
    border-radius: 15px;
    border-left: 3px solid #FFB63C;
}

.audit-request-box.leaderboard-inner tr {
    vertical-align: middle;
    display: block;
    margin: 0 0 10px 0;
}

tr.second-winner {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 91.33%);
    border-left: 3px solid #E6E6F4;
    border-radius: 15px;
}

tr.third-winner {
    background: linear-gradient(90deg, rgb(17 234 221 / 23%) 0%, rgba(255, 255, 255, 0.00) 91.33%);
    border-left: 3px solid #3CE8FF;
    border-radius: 15px;
}

.audit-request-box.leaderboard-inner tr td img {
    width: 35px;
}

tr.common-section {
    background: #300467;
    border-radius: 15px;
}

.leaderboard-info-top {
    margin-bottom: 50px;
}

.filter-area-top.select-prediction .css-b62m3t-container {
    width: 180px;
}

.filter-area-top.select-prediction .css-13cymwt-control {
    width: 180px;
}

.prediction-box-outer {
    position: relative;
}

.quiz-listing {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    top: -10px;
    right: 10px;
    z-index: 99;
}

.quiz-listing p {
    background: #a550d8;
    color: #fff;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 5px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.risk-free-batch-listing {
    position: absolute;
    top: 15px;
    left: -19px;
}

.risk-free-batch-listing p {
    transform: rotate(316deg);
    font-size: 11px;
    background: #fe3e75;
    color: #fff;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.poll-listing p {
    background: #2196F3;
    color: #fff;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 5px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prediction-detail-right-outer {
    position: relative;
}

.risk-free-batch.risk-free-batch-detail {
    left: -24px;
    top: 15px;
    position: absolute;
}

.risk-free-batch.risk-free-batch-detail p {
    font-size: 13px;
    transform: rotate(316deg);
    padding: 0 22px;
    height: 25px;
    border-radius: 0;
    background: #fe3e75;
    color: #fff;
    width: fit-content;
}

p.prediction-only-view a:hover {
    color: #246efc;
    text-decoration: underline;
}

p.prize-pool span {
    color: #fe3e75;
    font-weight: 700;
    font-size: 17px;
}

p.prize-pool {
    color: #1a003d;
    font-weight: 600;
    margin-bottom: 8px;
}

.risk-free-batch.quiz-batch {
    right: 70px;
}

.risk-free-batch.quiz-batch p {
    background: #2196F3;
}

.option-swiper .swiper-button-prev:after,
.option-swiper .swiper-button-next:after {
    background: #1a003d;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 900;
    font-size: 8px;
    border-radius: 50%;
    min-width: 18px;
    cursor: pointer;
    min-height: 18px;
    z-index: 99;
}

.option-swiper .swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: -4px;
    right: auto;
}

.option-swiper .swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: -3px;
    left: auto;
}

.option-swiper {
    position: relative;
    padding: 10px 0;
}

.css-1821uf4 {
    margin: 0 auto;
}

.prediction-piechart .css-1v603wu-MuiResponsiveChart-container {
    display: flex;
    margin: 0 auto !important;
    width: 140px;
    height: 140px;
    max-width: 140px;
    max-height: 140px;
}

.prediction-detail-left-content a {
    word-wrap: break-word;
    font-size: 15px;
}

.listing-option-area-inner {
    padding: 0 12px;
}

.listing-option-area-inner h6 {
    color: #1a003d;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.listing-option-area-inner p {
    font-size: 10px;
    font-weight: 700;
    margin: 0;
}

.listing-option-area-inner h6 i {
    margin-right: 6px;
}

.listing-option-area-inner p span {
    color: #a550d8;
    font-weight: 600;
    padding: 0;
    font-size: 12px;
    margin: 0;
}

.radio-btn-option.prediction-detail-options .form-check label {
    font-size: 13px;
    color: #1a003d;
    cursor: pointer;
    line-height: 20px;
}

.prediction-detail-option-section {
    margin-bottom: 30px;
    margin-right: 10px;
}

.radio-btn-option.prediction-detail-options .form-check {
    border: 1px solid #1a003d12;
    margin: 0;
    padding: 2px 20px;
    width: 100%;
    max-width: 220px;
    border-radius: 120px;
    background: #a550d81a;
    cursor: pointer;
}

.radio-btn-option.prediction-detail-options .active.form-check label {
    color: #fff;
    opacity: 1;
}

.radio-btn-option.prediction-detail-options .active.form-check {
    background: #a550d8ad;
    border: 1px solid #a550d8cf;
}

.prediction-market-right.mid-box-area {
    width: 100%;
    background: #e0c3f240;
    padding: 2px 7px;
    border-radius: 10px;
    margin: 0 0 10px 0;
}

.css-1nmdiq5-menu {
    z-index: 999 !important;
}

.filter-area-top.soloprediction-filter .css-1nmdiq5-menu {
    width: 130px;
}

.disclamier p {
    display: flex;
}

.disclamier p {
    font-style: italic;
    color: #fd3f75;
    font-size: 15px;
    flex-wrap: wrap;
}

.disclamier p span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 800;
}

.disclamier {
    margin-top: 8px;
}

p.banner-top-message {
    background: red;
    color: #fff;
    text-align: center;
    text-decoration: underline;
    font-weight: 500;
    padding: 2px 0;
    cursor: pointer;
}

p.banner-top-message.blue-message-top {
    background: #2824EF;
}

.no-data-area.main-no-data.table-no-data.board-no-data img {
    filter: invert(1);
    width: 40px;
}

.no-data-area.main-no-data.table-no-data.board-no-data p {
    color: #fff;
}

td.board-no-data-outer {
    min-width: 1030px !important;
}

.no-data-area.main-no-data.table-no-data.board-no-data {
    width: 950px;
}

.prediction-content-outer {
    width: fit-content;
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
    margin-right: 60px;
}

.prediction-content-outer::-webkit-scrollbar {
    width: 3px;
}

.prediction-content-outer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.prediction-content-outer::-webkit-scrollbar-thumb {
    background-color: #a550d8ad;
}

.poll-prizes-ara {
    position: absolute;
    z-index: 1;
    right: 63px;
    top: -15px;
    cursor: pointer;
}

.poll-prizes-ara img {
    width: 20px;
    min-width: 20px;
}

img.diamond-icon {
    width: 20px;
    min-width: 20px;
}

.poll-prizes-ara.detail-side-poll-prize {
    position: absolute;
    right: 110px;
    top: 12px;
}

.prediction-detail-right-outer .poll-prizes-ara img {
    position: absolute;
    right: 50px;
    top: 31px;
}

.selected-section-outer {
    display: flex;
    /* flex-wrap: wrap; */
}

.selected-option-area {
    margin-bottom: 30px;
}

.selected-option-area ul li {
    list-style: none;
}

.selected-option-area ul li {
    list-style: none;
    display: flex;
    align-items: center;
}

.selected-option-area ul li p {
    margin: 0;
    padding: 2px 20px;
    width: 100%;
    max-width: 220px;
    border-radius: 120px;
    background: #c288e5;
    cursor: pointer;
    border: 1px solid #a550d8cf;
    color: #fff;
    font-size: 13px;
    height: 38px;
    line-height: 15px;
    align-items: center;
    display: flex;
}

.prediction-detail-left-content ul li {
    padding: 0 0 8px 0;
}

.prediction-detail-left-content ul li span {
    width: 30px;
    height: 20px;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: #a550d8ad;
    border: 1px solid #aa5ada;
}

h2.multi-choice-predict {
    font-size: 40px;
}

.countdown-bottom-area p {
    margin-right: 12px;
    font-size: 16px;
    font-weight: 800;
    color: #fe3e75;
    font-style: italic;
}

.countdown-bottom-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.countdown-bottom-area span {
    color: #fd3f75;
    font-size: 16px;
    font-weight: 500;
}

.redem-outer-area {
    padding: 50px 0;
}

.redem-inner-area h2 {
    font-size: 30px;
    color: #1a003d;
    font-weight: 800;
    margin-bottom: 30px;
    text-align: center;
}

.redem-points-area {
    background: #1a003d;
    color: #fff;
    padding: 12px 20px 20px;
    border-radius: 13px;
    position: relative;
    height: 100%;
}

.redem-points-area h3 {
    font-size: 45px;
}

.redem-button {
    display: flex;
    align-items: center;
}

.redem-points-area img {
    position: absolute;
    width: 37px;
    min-width: 35px;
    right: -32px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.redem-inner-area .col-md-3 {
    padding-right: 0;
}

.redem-inner-area button {
    width: fit-content;
    margin: 20px auto;
    display: flex;
    background: #fd3f75 !important;
    color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 8px;
}

.redem-inner-area h6 {
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    color: #1a003d;
}

.redem-outer-area hr {
    margin: 20px 0 !important;
    opacity: 0.1 !important;
}

.folio-amount-area p {
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 20px;
}

.folio-amount-area p span {
    color: #ffffff;
    font-size: 27px;
}

.folio-amount-area h5 {
    font-size: 35px;
    color: #1a003d;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.redem-points-area.folio-amount-area {
    padding: 30px;
}

.discount-bottom-btn button {
    width: fit-content;
    display: flex;
    background: #fd3f75 ;
    color: #fff ;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    margin: 0 5px 0 0;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.redemption-options h6 {
    color: #fd3f75;
    font-weight: 700;
    margin: 15px 0;
    text-align: center;
    font-size: 15px;
}

.redemption-options ul li span {
    color: #fd3f75;
    font-weight: 700;
}

.redemption-options ul {
    padding-left: 0;
    display: flex;
}

.redem-points-area.balance-area ul li {
    font-size: 14px;
    border: 1px solid #ffffff47;
    padding: 0 7px;
    line-height: 23px;
    border-radius: 7px;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.redem-points-area.balance-area ul {
    display: flex;
    margin: 10px 0 0 0;
}

.redem-points-area.balance-area ul li.active {
    background: #fd3f75;
}

.redem-points-area.balance-area p {
    font-size: 14px;
    font-weight: 300;
}

.redem-history h5 {
    color: #1d0043;
    font-weight: 800;
    margin-bottom: 11px;
}

.board-filters.redem-history-filter .mb-3 {
    display: inline-flex;
    align-items: center;
}

.redem-points-area .form-control::placeholder {
    color: #fff;
}

.redem-points-area .form-control {
    background: none;
    border: 1px solid #ffffff29;
    padding: 8px 10px;
    border-radius: 10px;
    color: #fff;
}

.redem-points-area.balance-area h6 {
    color: #fff;
    font-style: normal;
    font-weight: 400;
    text-align: end;
}

.redem-points-area.balance-area h6 span {
    font-size: 20px;
    font-weight: 500;
}

.redemption-options ul li {
    color: #1a003d;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    background: #fd3f7526;
    padding: 10px;
    text-align: center;
    margin: 0 3px;
    border-radius: 10px;
    font-style: italic;
}

.main-search-area input::placeholder {
    font-size: 14px;
    color: #948c8c;
}

.main-search-area input,
.main-search-area .form-control:focus {
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14) !important;
    width: 100%;
    padding: 12px 35px 12px 10px;
    background-color: rgba(255, 255, 255, 0.12);
    color: #000;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-color: #ced4da;
}

.main-search-area i {
    position: absolute;
    right: 15px;
    bottom: 16px;
    font-size: 18px;
    color: #1a003d;
    cursor: pointer;
}

.main-search-outer {
    position: relative;
    width: 80%;
    margin: 20px auto 0;
}

.main-search-area {
    position: relative;
}

.pin-icon i {
    color: #1a003d;
    font-size: 12px;
    transform: rotate(43deg);
}

.search-area-dropdown {
    position: absolute;
    top: 58px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14) !important;
    width: 100%;
    padding: 12px;
    background-color: #fff;
    color: #000;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 1px solid #ced4da;
    z-index: 999;
    max-height: 300px;
    overflow: auto;
}

.search-area-dropdown::-webkit-scrollbar {
    width: 3px;
}

.search-area-dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.search-area-dropdown::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.search-no-data p {
    font-size: 15px;
    color: #000;
    font-weight: 700;
    margin-top: 8px;
}

.search-no-data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 150px;
}

.pin-icon {
    position: absolute;
    z-index: 1;
    background: #e0c3f2;
    width: 18px;
    height: 18px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    left: -3px;
    top: -3px;
    cursor: pointer;
}

.redem-top-info {
    text-align: center;
    margin-bottom: 28px;
}

.redem-top-info h6 {
    font-style: normal;
    color: #fd3f75;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 800;
}

.redem-top-info p a {
    color: #fd3f75;
    font-weight: 700;
}

.redem-top-info p {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;
}

.search-dropdown-inner-left img {
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 10px;
}

.redem-top-info ul li {
    font-size: 14px;
    color: #fd3f75;
    font-weight: 600;
    font-style: italic;
    line-height: 24px;
}

.seacrh-dropdown-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid #00000017;
}

.redem-outer-area .audit-request-box.leaderboard-inner tr th,
.redem-outer-area .audit-request-box.leaderboard-inner tr td {
    min-width: 200px !important;
}

.seacrh-dropdown-inner:last-child {
    border-bottom: none;
}

.search-dropdown-inner-left p {
    color: #1a003d;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-top: 2px;
}

.search-dropdown-inner-left h5 {
    font-size: 15px;
    font-weight: 700;
    color: #a856d9;
}

.search-dropdown-inner-left h6 {
    font-size: 14px;
    color: #1a003d;
    font-weight: 600;
}

.dropdown-inner-info img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    min-width: 18px;
    min-height: 18px;
    margin: 0 4px;
}

.search-dropdown-inner-left {
    display: flex;
    align-items: center;
}

#ads-home-right {
    position: fixed;
    right: 0;
    top: 100px;
}

.search-dropdown-inner-left span {
    color: #A550D8;
    font-size: 14px;
    font-weight: 700;
}

#ads-home-left {
    bottom: 46px;
    left: 0;
    position: fixed;
}

#ads-banner-top {
    display: flex;
    justify-content: center;
}

.steps-left-area h5 {
    width: 40px;
    background: #1a003d;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    line-height: 13px;
}

th.leaderboard-address button {
    background: none !important;
    border: none;
    padding: 0;
    margin-left: 6px;
    font-size: 14px;
}

#ads-footer {
    display: flex;
    justify-content: center;
}

.folio-popup .modal-header {
    border: none;
}

.folio-popup .modal-body {
    padding-top: 0;
}

.folio-icon:hover {
    transform: translateY(-5px);
}

.folio-icon {
    position: absolute;
    right: 30px;
    bottom: 105px;
    transition: all 0.3s ease-out;
}

.folio-icon img {
    width: 70px;
    cursor: pointer;
}

.folio-popup .modal-body p {
    text-align: center;
    padding: 0 30px 30px;
    font-weight: 700;
    color: #1a003d;
}

.folio-popup .modal-body p a {
    color: #fe3e75;
    font-weight: 900;
}

.folio-popup .modal-content {
    border-radius: 25px;
}

.dot-icon {
    display: inline-block;
    width: 9px;
    height: 9px;
    background: #df346d;
    border-radius: 22pc;
    position: absolute;
    top: 10px;
    right: 8px;
}

.notification-icon.nav-link {
    position: relative;
}

.risk-free-batch-listing.free-access-tag p,
.risk-free-batch.risk-free-batch-detail.free-access-tag p {
    background: #0db0f0 !important;
}

.notification-mob.navbar-nav {
    display: none;
}

.contraffic_home_add {
    display: flex !important;
    justify-content: center;
    margin: 0 auto 25px;
}

.prediction-detail-add {
    display: block !important;
    margin-top: 30px;
}

.contraffic_outcome-top-ad {
    margin-bottom: 2px;
    display: none !important;
}

.prediction-detail-add img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
}

.follow-steps-ad-mid {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.follow-steps-ad-mid img {
    width: 100% !important;
    height: 100% !important;
}

button.login-btn-top {
    background: #fff !important;
    border: none;
    color: #1a003d !important;
    font-weight: 800;
    padding: 0 20px;
    height: 40px;
    border-radius: 10px;
    width: fit-content;
}

.login-popup .modal-content {
    border-radius: 40px;
    border: 1px solid rgb(0 0 0 / 26%);
}

.login-popup .modal-header {
    border: none;
    padding-bottom: 5px;
    justify-content: center;
}

.login-popup .modal-title {
    color: #1a003d;
    font-size: 32px;
    font-weight: 900;
}

.login-popup .modal-body form input::placeholder {
    font-size: 13px;
}

.login-popup .modal-body form input {
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding: 9px;
    color: #000;
    font-size: 15px;
}

.login-popup .modal-body button {
    margin-top: 36px !important;
    display: flex;
    background: #1a003d;
    border: none;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 40px;
    border-radius: 10px;
    width: 100%;
    justify-content: center;
}

.login-popup .modal-body .form-label {
    margin: 0;
    font-size: 15px;
    color: #000;
    font-weight: 600;
}

.or-option {
    text-align: center;
    margin: 15px 0;
}

.login-popup .modal-body .connect-wallet-btn button {
    margin: 0 !important;
    border: 2px solid #1a003d;
    color: #1a003d;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 45px;
}

.login-popup .modal-body {
    padding: 10px 30px 30px;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
}

.social-links a img {
    width: 40px;
    background: #63636326;
    padding: 7px;
    border-radius: 10px;
    margin: 0 4px;
    color: powderblue;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
}

.login-popup button.btn-close {
    position: absolute;
    font-size: 15px;
    right: 25px;
    cursor: pointer;
    top: 27px;
}

button.buy-more-time {
    background: #fc3c74 !important;
    color: #ffffff !important;
    font-size: 17px;
    border: none;
}

.nft-types-popup .modal-body ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.nft-types-popup .modal-body ul li {
    background: #fd3f7526;
    border-radius: 10px;
    color: #1a003d;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
    padding: 8px;
    text-align: center;
    width: 48%;
    margin: 5px 4px;
    cursor: pointer;
}

.nft-types-popup .modal-body ul li p {
    color: #fd3f75;
    font-size: 13px;
    font-weight: 700;
}

.nft-types-popup .modal-body ul li a {
    color: #1a003d;
    text-decoration: unset;
}

.extension-window {
    background: #a550d8;
    color: #fff;
    padding: 0 7px;
    border-radius: 7px;
    line-height: 24px;
    margin-top: 7px;
    text-align: center;
}
.marketplace-content-area p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: #fc3c74;
    font-weight: 600;
}
.extension-window p {
    font-size: 12px;
}

.profile-details-section {
    padding: 50px 0;
    min-height: calc(100vh - 297px);
}

.sub-heading {
    font-size: 25px;
    margin: 15px 0 15px;
    font-weight: 700;
    color: #000;
}

/* .user-detail-box.profile-details-content {
    padding: 0 18px 18px 18px;
    border: 1px solid #00000042;
    border-radius: 21px;
} */

.dropdown-item:hover .banner-hover {
    display: block;
}

.label-text {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
}

.wallet-details-text {
    font-size: 20px;
    color: #1a003d;
    font-weight: 700;
    word-wrap: break-word;
}

.wallet-details-text .fa.fa-copy {
    background: #1a003d;
    color: #fff !important;
    padding: 6px;
    font-size: 14px;
    border-radius: 7px;
}

.under-profile-box {
    padding: 16px 22px;
    background: #ffffff12;
    margin-bottom: 11px;
    border-radius: 10px;
    border: 1px solid #1a003d2b;
}

button.export-section {
    padding: 10px 16px 10px 13px;
    background: transparent;
    border: 1px solid;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1a003d;
}

button.export-section img {
    width: 27px;
    margin-bottom: 0;
    margin-right: 15px;
}

button.export-section span {
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    font-size: 15px;
}

span.last-text {
    font-size: 13px;
    border-radius: 4px;
    margin-left: 4px;
    color: #fc3c74;
}

p.sub-label {
    font-size: 13px;
    font-weight: 600;
}

.another-balacne {
    padding: 7px 18px;
    border-radius: 12px;
    margin-top: 11px;
    /* border: 1px solid #1a003d29; */
    margin-right: 9px;
    border-top: 1px solid #00000030;
    background: #0000000d;
}

.time-extension-info-area ul li {
    background: none !important;
    padding: 1px 0 !important;
    margin: 0 !important;
    width: unset !important;
    color: #1a003d !important;
    list-style: disc;
    font-style: unset !important;
    text-align: left !important;
}

.time-extension-info-area {
    text-align: left;
    border-top: 1px solid #00000014;
    padding-top: 15px;
    margin-top: 15px;
}

.time-extension-info-area ul {
    display: unset;
    justify-content: left !important;
    padding-left: 13px;
}

.time-extension-info-area h6 {
    color: #fd3f75;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
}

p.extension-bets {
    font-size: 14px;
}

tr.highlight-prize {
    background: #fe3e752e;
}

p.bonus-prize button {
    background: none !important;
    border: none !important;
    box-shadow: none;
    color: #1a003d !important;
    padding: 0;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

p.bonus-prize button span {
    color: #fc3c74;
    font-weight: 700;
}

.mint-btn button {
    border: none;
    width: fit-content;
    font-size: 15px;
    padding: 9px 20px;
    margin-top: 3px;
}

.subscribe-area {
    background-image: url("../images//main-banner.png");
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    margin: 20px 0;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    background-position: left;
    border: 1px solid #00000017;
}

.subscribe-area img {
    position: absolute;
    top: 0;
    width: 45px;
    left: 1px;
}

.subscribe-area h4 {
    text-align: center;
    color: #1a003d;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.subscribe-email-area {
    display: flex;
    align-items: center;
}

.subscribe-area label {
    margin: 0;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.subscribe-email-area input::placeholder {
    font-size: 13px;
    color: gray;
}

.subscribe-email-area input {
    background: #fff;
    height: 47px;
    margin-right: 7px;
    border: 1px solid #00000014;
    border-radius: 10px;
    font-size: 14px;
    color: #000;
}

.subscribe-email-area .form-control:focus {
    border-color: #00000014;
}

.subscribe-email-area button {
    background: #1a003d !important;
    border: none !important;
    font-size: 15px;
    padding: 12px 15px;
    border-radius: 10px;
}

.nft-types-popup .modal-body {
    padding-top: 0;
}

.nft-types-popup .modal-body p {
    font-size: 12px;
    line-height: 18px;
    font-style: italic;
    font-weight: 600;
    color: #fd4177;
    margin-bottom: 12px;
}

.subscribe-area p {
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
    padding: 0 25px;
}

a.redeem-now-btn {
    font-weight: 700;
    color: #fc3c74 !important;
    font-size: 13px;
    cursor: pointer;
}

.transaction-top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.transaction-top-section button {
    background: #1a003d !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 15px;
    padding: 9px 20px;
    border-radius: 10px;
}

.view-nft-modal .modal-content {
    border-radius: 22px !important;
}

.view-nft-modal .modal-title.h4 {
    font-size: 22px;
    color: #1a003d;
    font-weight: 600;
}

.under-view-nft {
    padding: 5px 11px;
    /* border: 1px solid #00000029; */
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* background: #635b5d2b; */
    margin-bottom: 13px;
    border: 1px solid #635b5d6b;
    height: 100%;
}

.under-view-nft p {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #1a003d24;
    padding: 2px;
    font-size: 13px;
}

/* .under-view-nft p span {
    color: #fe3e75;
} */
.Valid-text {
    background: #fe3e7542;
    padding: 0px 7px;
    border-radius: 9px;
    font-size: 12px;
    height: 25px;
    display: inline-block;
}

.under-view-nft p:last-child {
    border-bottom: none;
}

.view-nft-body::-webkit-scrollbar {
    width: 3px;
    height: 2px;
}

.view-nft-body::-webkit-scrollbar-track {
    background: #b5b5b5;
}

.view-nft-body::-webkit-scrollbar-thumb {
    background-color: #1d0043;
    /* outline: 1px solid slategrey; */
}

.view-nft-modal .modal-dialog {
    max-width: 1180px;
}

.subnodes p {
    display: flex;
    background: #80808021;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #1a003d !important;
    font-size: 11px;
    height: 28px;
    align-items: center;
}

.highlisgtd-text {
    color: #fc3c74;
    font-weight: 700;
    margin-left: 4px;
}

.subnodes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #1a003d24;
    border-top: 1px solid #1a003d24;
    padding: 8px 0;
}

.share-referral-modal .modal-content {
    border-radius: 30px;
}

.share-referral-modal .modal-title.h4 {
    color: #1a003d;
    font-weight: 800;
}

.share-referral-modal .modal-header {
    border-bottom: none;
}

.share-referral-modal span.qr-code-box.share-qr-code img {
    margin-bottom: 15px;
}

.share-referral-modal .share-modal-new.modal-body button {
    margin: 4px 8px 10px 0;
}

.user-detail-box.profile-details-content i.fa.fa-share {
    cursor: pointer;
}

/* .bonus-points {
    margin-top: 20px;
}
.bonus-points h6 {
    color: #1a003d;
    font-size: 20px;
}
.bonus-points p {
    color: #fd3f75;
    font-size: 30px;
    font-weight: 800;
}
.bonus-points span {
    color: dimgray;
    font-size: 16px;
    font-weight: 500;
}
.bonus-loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    background: rgb(255 226 234);
    position: relative;
    overflow: hidden;
}
  .bonus-loader::after {
    content: '';
    width: 0%;
    height: 4.8px;
    background-color: #FFF;
    font-size: 15px;
    background-image: linear-gradient(45deg, rgb(253 63 117) 25%, transparent 25%, transparent 50%, rgb(253 63 117) 50%, rgb(253 63 117) 75%, transparent 75%, transparent);
    background-size: 1em 1em;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animFw 10s ease-in infinite,  barStripe 1s linear infinite;
}
  
  @keyframes barStripe {
    0% {
      background-position: 1em 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  @keyframes animFw {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .bonus-range p {
    font-size: 12px;
    color: #1a003d;
    font-weight: 700;
    border: 1px solid #1a003dbf;
    line-height: 0;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    border-radius: 4px;
    margin-bottom: -5px !important;
}    
.bonus-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 */
.bonus-countdown span {
    font-size: 14px;
    font-weight: 700;
    color: #1a003d;
}

.bonus-points {
    margin-top: 20px;
}

.bonus-points h6 {
    color: #1a003d;
    font-size: 20px;
}

.bonus-points p {
    color: #fd3f75;
    font-size: 30px;
    font-weight: 800;
}

.bonus-points span {
    color: dimgray;
    font-size: 16px;
    font-weight: 500;
}

.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
    padding-bottom: 3px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.ads-detail-page {
    margin-top: 20px;
    display: block;
}
.ads-detail-page div {
    width: 300px !important;
}
.contraffic_ad div {
    margin: 0 auto;
    display: block !important;
}
span#ct_c6jwm7LFHWb {
    display: none;
}
.ads-home-right {
    position: fixed;
    right: 0;
    top: 100px;
}
.ads-home-left {
    bottom: 46px;
    left: 0;
    position: fixed;
}   
.contraffic_ad {
    display: block !important;
}

.text-redeem {
    color: #fd3f75;
}
.discount-bottom-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.discount-bottom-btn button:hover {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.08), inset 0 0 0 1px rgba(252, 232, 3, 0.08);
    color: #fd3f75;
    transform: translate(0, -0.25rem);
    background-color: transparent;
    border: 1px solid #fd3f75;
}
.paypal-price-message {
    font-size: 18px;
    color: #000;
}
button.export-section i.fa.fa-refresh {
    color: #fd3f75;
    margin-right: 10px;
    font-size: 18px;
}
.marketplace-section {
    padding: 31px 0;
}
.marketplace-content-area h2 {
    color: #401584;
    font-family: Inter;
    font-size: 37.745px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.marketplace-outer {
    border-radius: 12px;
    background: #FBF4FF;
    padding: 15px;
    height: 100%;
    cursor: pointer;
}
.marketplace-body h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 7px;
}
.marketplace-body h6 span {
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.marketplace-body {
    padding: 13px 0 0 0;
}
.marketplace-btn, .marketplace-btn:hover, .marketplace-btn:active {
    border-radius: 8px;
    background: #1A003D;
    color: #fff !important;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 30px;
    margin-top: 10px;
}
.marketplace-header {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 144px;
    /* height: 100%; */
}
.marketplace-header img {
    width: 48%;
}
.marketplace-btn.stroke-btn.btn.btn-unset {
    border: 1px solid #1A003D;
    background: transparent;
    color: #1A003D !important;
}
.marketplace-header img.nft-two-module {
    height: 144px;
    width: 48%;
    object-fit: cover;
}
.marketplace-header img.nft-one-module {
    width: 100%;
}
.margin-btm{
    margin-bottom: 12px;
}
.marketplace-header.marketplace-detail img {
    width: 100%;
}
.marketplace-outer.position-relative.marketplace-under-details {
    border-radius: 13.333px;
    background: #FFF;
    box-shadow: 0px 1.111px 18.889px 0px rgba(0, 0, 0, 0.25);
}
.filter-area-top .css-tr4s17-option {
    background-color: #1a003d !important;
    white-space: nowrap;
}
.filter-area-top .css-1nmdiq5-menu {
    width: fit-content !important;
    right: 0;
    border-radius: 12px;
    overflow: hidden;
}
.stone-img {
    position: absolute;
    top: -24px;
    right: 26px;
}
 .dashboard-area-heading .nav-tabs .nav-link.active {
    border-radius: 8px;
    border: 1px solid #1A003D;
    background: #1A003D !important;
    color: #fff !important;
}
.dashboard-area-heading .nav-link{
    border-radius: 8px !important;
    border: 1px solid #1A003D !important;
    color: #1A003D !important;
    background: #fff !important;
}
.dashboard-area-heading .nav-tabs{
    gap: 12px;
}
.dashboard-area-heading  .tab-content {
    padding: 12px 0;
}
.refresh-btn {
    display: flex;
    align-items: center;
    margin: 13px 0 10px;
}

.export-section i {
    color: #fc3c74;
    margin-left: 10px;
    cursor: pointer;
}
.paypal-message-disable {
    opacity: 0.4;
}
.audit-request-box tr td .form-check-input {
    border: 1px solid #bababa !important;
    margin: 9px;
}
.audit-request-box .form-check-input:checked {
    background-color: #1d0043;
    border-color: #1a003d;
}
.success-msg a{
    color: #fc3c74; 
}
.success-msg {
    font-size: 13px;
    line-height: 24px;
    color: #000;
    font-weight: 600;
}
.marketplace-area {
    min-height: 53vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.marketplace-area .row{
    width: 100%;
}
.loading {
    color: #fc3c74;
    display: inline-block;
    position: relative;
    font-size: 33px;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
  }
  .loading::after {
    content: '';  
    width: 5px;
    height: 5px;
    background: currentColor;
    position: absolute;
    bottom: 0;
    right: -5px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  .under-nft-holding .css-1nmdiq5-menu {
    width: 100% !important;
    right: 0;
    border-radius: 12px;
    overflow: hidden;
}
  h6.nft_expired {
    font-weight: 600;
    font-size: 18px;
    color: red;
    }
  
  @keyframes animloader {
    0% {
      box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 10px 0 #fc3c74, 20px 0 rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 10px 0 #fc3c74, 20px 0 #fc3c74;
    }
  }
  .explore-top-filter.under-nft-holding {
    position: absolute;
    top: 0;
    right: 0;
}
.dashboard-area-heading.management-heading {
    position: relative;
}
.ads-banner-top {
    display: none;
}
.wallet-details-text.sm-txt {
    font-size: 12px;
    line-height: 18px;
    margin-left: 6px;
}
.alert-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 10px 12px 23px;
}
.alert-content h4 {
    color: #441272;
    text-align: center;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.alert-content p {
    color: #1A003D;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
}
.alert-btn.btn.btn-primary {
    color: #1A003D !important;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #F5E4FF;
    border: none;
    padding: 11px 20px;
}
.alert-box .modal-title {
    width: 100%;
}
.alert-box .modal-header {
    border-radius: 30px 30px 0px 0px;
    background: #EAC7FF;
    text-align: center;
    padding: 25px;
}
.alert-box .modal-content {
    border-radius: 32px;
    width: 97%;
    margin: 0 auto;
}
.alert-box .btn-close {
    position: absolute;
    right: 20px;
    top: 19px;
    font-size: 25px;
    z-index: 999;
}
.all-badge {
    font-size: 14px;
    background: #d00d45;
}
.solo {
    background: #FE3975;
}
.mulitchoice {
    background: #0800FF;
}
.scores {
    background: #9A00FF;
}
.pump {
    background: #70AD47;
}
.under-profile-box.betfolio-solana-address span {
    font-size: 13px;
    line-height: 21px;
    display: inline-block;
    margin-bottom: 12px;
    font-weight: 500;
}
.under-profile-box.betfolio-solana-address .form-label {
    position: absolute;
    background: #fff;
    top: -12px;
    left: 14px;
    padding: 0 2px;
    font-size: 13px;
    color: #323232;
    font-weight: 600;
}
.under-profile-box.betfolio-solana-address .form-control {
    padding: 10px 14px;
    border: 1px solid #0000002e;
    border-radius: 10px;
    color: #000000;
    font-size: 13px;
    font-weight: 500;
}
.under-profile-box.betfolio-solana-address .form-control::placeholder{
    font-size: 13px;
}
button.submit-btn.btn.btn-primary {
    background: #fc3c74;
    padding: 5px 16px;
    border: none;
    border-radius: 9px;
    font-size: 13px;
}
.betfolio-solana-address{
    background: url("../images/bg-betfolio.png") no-repeat;
    background-position: top;
    background-size: cover;
}
.label-text.highlight {
    font-size: 18px;
    opacity: 1;
    color: #1a003d;
}
.text-center.success-modal img {
    width: 62px;
    margin-bottom: 15px;
}
span.info-text {
    font-size: 12px !important;
    color: #fc3c74;
    font-weight: 600;
}
/* ---Benjamin AI Page-->>> */
.benjamin-ai {
    padding: 50px 0px;
}
section.benjamin-ai h2 {
    color: #401584;
    font-family: Inter;
    font-size: 37.745px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.feature-card-boxe {
    border-radius: 12px;
    background: #FBF4FF;
    box-shadow: 0px 3px 34px 0px rgba(0, 0, 0, 0.13);
    padding: 20px;
}
.img-box {
    background: #401584;
}
.ai-feature p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.ai-feature{
    margin-top: 20px;
}
.cards-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px 27px;
    margin-top: 33px;
}
.feature-card-boxe {
    border-radius: 12px;
    background: #FBF4FF;
    box-shadow: 0px 3px 34px 0px rgba(0, 0, 0, 0.13);
    padding: 20px;
    flex: 0 0 30%;
    text-decoration: none;
}
.subscribe-btn button {
    border: 1px solid #1A003D;
    background: #1A003D;
    font-weight: 500;
    width: 237px;
    height: 51px;
}
.subscribe-btn button.prompt {
    color: #1A003D;
    font-weight: 500;
}
.subscribe-btn {
    display: flex;
    /* justify-content: center; */
    gap: 20px;
}
img.overlay-lock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}
.ai-feature a {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    color: #401584;
    margin-top: 9px;
    display: inline-block;
}
/* promptbuilder css */
.promptbuilder{
    margin: 50px 0;
}
.promptbuilder h1{
color: #401584;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
margin-bottom: 30px;
}
.hourly h5{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 20px;
}
.question-col p{
color: #616161;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.statement-label h6 {
    max-width: 20%;
    color: #2B2B2B;
    font-weight: 600;
    margin-bottom: 20px;
}
.promptbuilder .container-fluid {
    width: 96%;
}
.copy-btn-col button{
    border-radius: 4px;
background: #401584;
width: 100%;
height: 47px;
flex-shrink: 0;
float: right;
max-width: 180px;
}
.question-row {
    justify-content: space-between;
    margin-bottom: 20px;
}
.question-col{
    width: 20%;
}
.memecoin .question-col{
    width: 50%;
}
.memecoin .statement-label h6 {
    max-width: 25%;
}
.select-col{
    width: 63%;
}
.copy-btn-col{
    width: 15%;
}
.select-col select {
    height: 47px;
    font-size: 16px;
    color: #616161;
}
/* description area */

.description-area {
    padding: 15px 20px;
}
.description-area p{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
}
.description-area ul {
    list-style-type: disc;
    margin-left: 30px;
    line-height: 29px;
    margin-bottom: 10px;
}
/* media-query */
@media (max-width: 1600px) {
    .about-us-section .container {
        max-width: 1190px;
    }
    .profile-detail-area.profile-details-section .container {
        max-width: 1230px;
    }

    .ads-home-left {
        bottom: 8px;
    }
}

@media (max-width: 1500px) {
    .profile-detail-area.profile-details-section .container {
        max-width: 1140px;
    }
    .audit-request-box.leaderboard-inner tr th,
    .audit-request-box.leaderboard-inner tr td {
        min-width: 250px !important;
    }

    .about-us-section .container {
        max-width: 1130px;
    }

    .coming-soon {
        height: 520px;

    }

    .prediction-detail-portion {
        min-height: 500px;
    }
}

@media (max-width: 1440px) {
    .profile-details-section {
        height: 100%;
    }

    .ads-home-left {
        bottom: -141px;
        width: 110px;
    }

    #ads-home-right {
        top: 92px;
        width: 112px;
    }

    .about-outer-section+.footer-area .footer-left-area {
        margin-left: 92px;
    }
}

@media (max-width: 1400px) {
    h2.multi-choice-predict {
        font-size: 35px;
    }

    .ads-home-left,
    .ads-home-right {
        width: 109px;
    }

    .banner-area {
        padding: 0 0 90px;
    }

    .spacing-area-section.container {
        max-width: 1350px;
    }

    .banner-area-content h1 {
        font-size: 48px;
    }

    .banner-area-content p {
        font-size: 35px;
    }
}

@media (max-width: 1300px) {
    .prediction-detail-right-top-heading h6 {
        font-size: 21px;
        padding-left: 0;
    }
    .explore-top-filter.under-nft-holding {
        top: -9px;
        right: 40px;
    }
    .about-us-section .container {
        max-width: 1000px;
    }
}

@media (max-width: 1200px) {
    .subscribe-area p {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 0px;
        padding: 0 20px;
    }

    .betting-area p {
        font-size: 12px;
    }

    .header .navbar-light .navbar-nav .nav-link {
        margin: 0 4px;
        font-size: 15px;
        width: fit-content;
    }

    .selected-section-outer {
        flex-wrap: wrap;
    }

    .ads-home-right {
        position: fixed;
        right: 0;
        top: 180px;
    }

    h2.multi-choice-predict {
        font-size: 30px;
    }

    .bg-vector p {
        top: 33%;
    }

    .risk-free-batch.risk-free-batch-detail {
        right: 30px;
    }

    .prediction-detail-right-top-heading h6 {
        font-size: 19px;
        padding-left: 0;
        margin-bottom: 5px;
    }

    .betting-area {
        padding: 0 5px;
    }

    .prediction-detail-right {
        padding: 0 20px 20px;
    }

    .prediction-detail-image img {
        width: 170px;
        height: 170px;
        min-width: 170px;
        min-height: 170px;

    }

    .prediction-detail-right-top-heading {
        margin: 20px 0 10px 0;
        flex-wrap: wrap;
    }

    p.correct-answer {
        margin: 19px 0 0 0;
    }
}

@media (max-width: 991px) {
    .marketplace-content-area p {
        margin-bottom: 20px;
    }
    .badge-outer {
        margin-bottom: 13px;
    }
    .new-badge {
        position: relative;
        right: 0;
        top: 0;
        margin-left: 4px;
    }
    .profile-details-section {
        height: 100%;
    }

    .subscribe-area {
        margin: 20px 220px;
    }

    .subscribe-area img {
        left: -5px;
    }

    .notification-mob.navbar-nav {
        display: block;
    }

    .prediction-detail-right-outer {
        width: 80%;
        margin: 0 auto;
    }

    a.notification-icon.web-notification.nav-link {
        display: none;
    }

    .folio-icon img {
        width: 50px;
        cursor: pointer;
    }

    .notification-icon.nav-link {
        display: inline-block;
        width: fit-content;
    }

    .dot-icon {
        right: 0;
    }

    .folio-icon {
        position: absolute;
        right: 12px;
        bottom: 81px;
        transition: all 0.3s ease-out;
    }

    .prediction-detail-right-outer .poll-prizes-ara img {
        position: absolute;
        right: 150px;
        top: 31px;
    }

    .poll-prizes-ara.detail-side-poll-prize {
        position: absolute;
        right: 210px;
        top: 12px;
    }

    .common-result {
        position: absolute !important;
        width: -moz-fit-content;
        width: fit-content !important;
        top: 17px !important;
        right: 150px !important;
        z-index: 99 !important;
    }

    .prediction-time-top-area {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 17px;
        left: 200px;
        z-index: 9;
    }

    .prediction-detail-left {
        margin-bottom: 30px;
    }

    .connect-wallet-btn .nav-link {
        margin: 0 0 10px !important;
    }

    .prediction-detail-right {
        margin: 0 120px;
    }

    .filter-area-top {
        justify-content: start;
    }

    .filter-area-top label {
        font-size: 14px;
    }

    .prediction-market-area h2 {
        font-size: 40px;
    }

    .connect-wallet-btn .nav-link {
        padding: 6px 10px;
    }
    .feature-card-boxe {
        padding: 20px;
        flex: 0 0 48%;
    }
    .cards-row {
        gap: 13px 18px;
    }
    /* ----promptbuilder---- */
    .question-col {
        width: 24%;
    }
    .select-col {
        width: 56%;
    }
    .question-col p,.select-col select {
     font-size: 14px;
    }
    .select-col select,.copy-btn-col button{
        height: 38px;
    }
    .statement-label h6{
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .subscribe-area {
        margin: 20px 70px;
    }
    .alert-content h4 {
        font-size: 27px;
    }
    .prediction-detail-image {
        margin-right: 0;
        text-align: left;
        margin-bottom: 15px;
    }

    .prediction-detail-left {
        flex-wrap: wrap;
    }

    .folio-icon {
        position: absolute;
        right: 12px;
        bottom: 150px;
        transition: all 0.3s ease-out;
    }

    #ads-home-left,
    #ads-home-right {
        display: none;
    }

    .banner-area-content {
        margin-top: 30px;
    }

    .main-search-outer {
        width: 100%;
    }

    .main-search-area {
        margin-bottom: 10px;
    }

    .explore-top-filter {
        justify-content: start;
    }

    .redem-points-area img {
        position: absolute;
        width: 33px;
        min-width: 33px;
        right: 43%;
        bottom: -20px;
        transform: translate(-50%, 0);
        z-index: 1;
        top: unset;
    }

    .prediction-detail-right-outer .poll-prizes-ara img {
        position: absolute;
        right: 45px;
        top: 31px;
    }

    .poll-prizes-ara.detail-side-poll-prize {
        position: absolute;
        right: 100px;
        top: 12px;
    }

    .prediction-time-top-area {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 17px;
        left: 80px;
        z-index: 9;
    }

    .common-result {
        position: absolute !important;
        width: -moz-fit-content;
        width: fit-content !important;
        top: 17px !important;
        right: 40px !important;
        z-index: 99 !important;
    }

    .transaction-top-heading h2 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .top-leaderboard-right p {
        font-size: 14px;
    }

    .top-leaderboard-left img {
        width: 160px;
        max-width: 160px;
    }

    .top-leaderboard-right {
        padding: 15px 0;
    }

    .audit-request-box tr th,
    .audit-request-box tr td {
        min-width: 160px;
    }

    .social-icons h6 {

        text-align: center;
    }

    .footer-left-area ul {
        justify-content: center;
        margin-bottom: 20px;
    }

    .social-icons ul {

        justify-content: center;
    }

    .banner-area {
        padding: 0 0 70px;
    }

    .banner-area-content p {
        margin: 8px 0 30px;
    }

    .prediction-detail-right {
        margin: 0;
    }

    .prediction-market-area h2 {
        font-size: 33px;
        margin-bottom: 20px;
    }

    .common-padding {
        padding: 30px 0;
    }

    .banner-area-content h1 {
        font-size: 40px;
    }

    .banner-area-content p {
        font-size: 30px;
    }
}

@media (max-width: 575px) {
    .subscribe-btn{
        flex-wrap: wrap;
    }
    .subscribe-area {
        margin: 20px 0 0 0;
    }
    .alert-box .modal-content {
        width: 67%;
    }
    .alert-content p, .alert-btn.btn.btn-primary {
        font-size: 15px;
    }
    .alert-box .modal-header {
        padding: 18px;
    }
    .alert-content {
        gap: 13px;
        padding: 0px 2px 13px;
    }
    .explore-top-filter.under-nft-holding {
        top: -22px;
        right: 0;
    }
    .contraffic_home_add div {
        height: unset !important;
    }
    .contraffic_home_add {
        margin: 20px 0;
    }

    .subscribe-email-area button {
        padding: 11px 15px;
    }

    p.correct-answer {
        padding: 0 30px;
    }

    .nft-types-popup .modal-body ul li {
        width: 47%;
    }

    .wallet-details-text {
        font-size: 18px;
    }

    .profile-details-section {
        padding: 28px 0;
    }

    .prediction-detail-add, .ads-detail-page {
        display: none !important;
    }

    .nft-types-popup .modal-body {
        padding: 10px 10px 22px;
    }

    .contraffic_outcome-top-ad {
        margin-bottom: 2px;
        display: block !important;
    }

    #ads-banner-top div {
        height: unset !important;
    }

    .redemption-options ul {
        flex-wrap: wrap;
    }

    .about-area-content a,
    .terms-services-area a {
        font-size: 14px;
    }

    .redemption-options ul li {
        margin: 3px;
        width: 100%;
    }

    .left-spacing-area {
        margin-left: 0;
    }

    .prediction-detail-image {
        margin-bottom: 10px;
        text-align: left;
    }

    .prediction-detail-left {
        flex-direction: column;
    }

    .banner-area-content img {
        position: absolute;
        right: -4px;
        top: -30px;
    }

    .prediction-detail-left-content {
        padding: 0 10px;
    }
    .ads-banner-top .follow-steps-ad-mid {
        margin-top: 0;
    }
    .marketplace-outer.position-relative.marketplace-under-details {
        margin-bottom: 22px;
    }
    .marketplace-content-area h2 {
        font-size: 27.745px;
    }
    .feature-card-boxe {
        padding: 20px;
        flex: 0 0 100%;
    }
}
#ct_coppTBARIlK{
    display: none !important;
}

@media (max-width: 480px) {
    .marketplace-content-area p {
        font-size: 12px;
        line-height: 17px;
        margin-top: 6px;
    }
    .alert-box .modal-content {
        width: 94%;
    }
    .explore-top-filter.under-nft-holding {
        top: -25px;
    }
    .dashboard-area-heading .nav-link {
        font-size: 13px;
    }
    .prediction-detail-right-top-heading {
        margin: 10px 0 10px 0;
    }

    .sub-heading {
        font-size: 20px;
    }

    p.correct-answer span {
        font-size: 16px;
    }

    .prediction-detail-right-top-heading h6 {
        margin-bottom: 0;
    }

    .prediction-detail-right-outer {
        width: 100%;
    }

    .folio-icon {
        bottom: 130px;
    }

    .common-padding.prediction-detail-portion {
        padding-top: 20px;
    }

    .folio-popup .modal-body p {
        padding: 0 7px 20px;
        font-size: 15px;
        line-height: 25px;
    }

    .main-search-area .form-control:focus,
    .main-search-area input {
        padding: 6px 35px 6px 10px;
    }

    .main-search-area i {
        bottom: 11px;
        right: 12px;
    }

    .folio-icon img {
        width: 45px;
    }

    p.banner-top-message {
        font-size: 14px;
    }

    .pin-icon {
        top: 23px;
    }

    .prediction-detail-right-outer .poll-prizes-ara img {
        position: absolute;
        right: 30px;
        top: 3px;
    }

    .poll-prizes-ara.detail-side-poll-prize {
        position: absolute;
        right: 80px;
        top: 12px;
    }

    .poll-prizes-ara {
        position: absolute;
        z-index: 1;
        right: 63px;
        top: 15px;
        cursor: pointer;
    }

    .prediction-detail-right-top-heading h6 {
        padding-left: 0;
    }

    .common-result {
        position: absolute !important;
        width: -moz-fit-content;
        width: fit-content !important;
        top: 17px !important;
        right: 20px !important;
        z-index: 99 !important;
    }

    .predict-payout-area p span {
        font-size: 15px;
    }

    .predict-payout-area p {
        font-size: 14px;
    }

    .predict-payout-area i {
        font-size: 16px;
    }

    .risk-free-batch.risk-free-batch-detail {
        right: 15px;
    }

    .filter-area-top.select-prediction .css-b62m3t-container {
        width: 120px;
    }

    .filter-area-top.select-prediction .css-13cymwt-control {
        width: 120px;
    }

    .leaderboard-info-top h6,
    .leaderboard-info-top p {
        margin-bottom: 5px;
    }

    .leaderboard-info-top {
        margin-bottom: 30px;
    }

    .leaderboard-info-top ul li {
        font-size: 14px;
    }

    .transaction-top-heading {
        padding: 0 10px;
    }

    .transaction-top-heading h2 img {
        width: 20px;
    }

    .top-leaderboard-right p {
        font-size: 13px;
        line-height: 22px;
    }

    .top-leaderboard-left img {
        width: 100px;
        max-width: 100px;
    }

    .transaction-top-heading h2 {
        font-size: 18px;
    }

    .leaderboard-card {
        padding: 20px;
    }

    .transaction-management-area {
        padding: 30px 0;
    }

    .leaderboard-card p {
        margin-bottom: 0;
    }

    .leaderboard-card h4 {
        font-size: 32px;
    }

    .leaderboard-card p {
        font-size: 15px;
    }

    .risk-free-batch-listing.free-access-tag p {
        background: #0db0f0 !important;
    }

    .footer-left-area ul li {
        list-style: none;
        margin: 0 15px;
    }

    .terms-services-area h6 {
        font-size: 17px;
    }

    .terms-services-area h2 {
        font-size: 21px;
        line-height: 30px;
    }

    .terms-services-area p {
        font-size: 14px;
    }

    .header .navbar-brand img {
        width: 55px;
        min-width: 55px;
    }

    .betting-area {
        margin-top: 9px;
    }

    .prediction-detail-right-top-heading {
        display: flex;
        flex-wrap: wrap;
    }

    .about-area-content h2 {
        font-size: 30px;
        margin-bottom: 6px;
    }

    .about-area-content p,
    .about-area-content ul li {
        font-size: 14px;
    }

    p.correct-answer {
        right: 15px;
    }

    .about-area-content h3 {
        font-size: 20px;
    }

    .about-area-content h5 {
        font-size: 15px;
        line-height: 25px;
    }

    .contact-form {
        margin: 50px 0;
    }

    .inner-contact-form label {
        font-size: 15px;
    }

    .inner-contact-form button {
        margin: 30px auto 0;
    }

    .inner-contact-form {
        padding: 20px;
    }

    .inner-contact-form h2 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .follow-steps-popup.modal.show .modal-content {
        width: 100%;
    }

    .footer-content {
        text-align: center;
    }

    .social-icons ul {

        justify-content: center;
    }

    .prediction-lost-area {
        padding: 10px;
    }

    .prediction-market-right {
        width: 100%;
        ;
    }
    .step-area {
        margin: 33px 10px;
    }
    .steps-content h6 {
        font-size: 18px;

    }
    .connect-wallet-box .modal-body img {
        width: 100px;
    }

    .connect-wallet-box .modal-body h3 {
        font-size: 22px;
    }

    .congratulation-bg-area img {
        width: 70px;
        min-width: 70px;
    }

    .congratulation-content-area p {
        font-size: 14px;
    }

    .congratulation-area {

        padding: 0 10px 10px;
    }

    .prediction-lost-content p {
        font-size: 14px;
    }

    .leader-board-top {
        flex-wrap: wrap;
    }

    .prediction-detail-image img {
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;
    }

    .prediction-detail-left-content h5 {
        font-size: 23px;

    }

    .progress-bar-info {
        margin: 8px 0 0;
        flex-wrap: wrap;
    }

    .prediction-detail-right {


        padding: 0 15px 15px;
    }

    .prediction-market-right {
        margin-top: -43px;
    }


    .prediction-market-right h5 {
        margin-bottom: 5px;
        margin-top: 50px;
    }

    .bg-vector {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 100px;
    }

    .quiz-listing {
        position: absolute;
        width: -moz-fit-content;
        width: fit-content;
        top: 20px;
        right: 10px;
        z-index: 99;
    }

    .prediction-market-inner {
        display: flex;
        flex-wrap: wrap;
    }

    .prediction-market-right ul {
        width: unset;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: start;
    }

    .prediction-market-right ul li {
        width: 100%;
    }

    .prediction-market-area h2 {
        font-size: 24px;
    }

    .banner-area-content img {

        display: none;
    }

    .banner-area-content h1 {
        font-size: 27px;
    }

    .banner-area-content p {
        font-size: 23px;
        margin: 2px 0 22px;
    }

    .banner-area {
        padding: 0 0 60px;
    }

    div#ads-banner-top div {
        height: 60px;
    }
    .description-area ul{
        font-size: 12px;
    line-height: 22px;
    }
    .description-area p{
        font-size: 14px;
    }
    /* ---promptbuilder---- */
    .question-col, .select-col, .copy-btn-col {
        width: 100%;
    }
    .question-row{
        flex-direction: column;
        gap: 15px;
    }
    .copy-btn-col button{
        float: left;
    }
    .promptbuilder h1{
        font-size: 22px;
    }
    .hourly h5{
        font-size: 16px;
    }
    .statement-label h6,.memecoin .statement-label h6{
        max-width: 100%; 
    }
    .memecoin .question-col {
        width: 100%;
    }
    .copy-btn-col button{
        height: 32px;
        width: 100px;
        font-size: 14px;
    }
    .hourly{
        margin-bottom: 40px;
    }
    .question-col p, .select-col select {
        font-size: 13px;
    }
}

@media (max-width: 420px) {
    .discount-bottom-btn button {
        font-size: 13px;
        padding: 4px 8px;
    }
    .subscribe-email-area button {
        padding: 11px;
        font-size: 14px;
    }

    .subscribe-area h4 {
        font-size: 17px;
        margin-bottom: 15px;
    }

    .prdiction-box-bottom button {
        font-size: 13px !important;
    }

    .login-popup .modal-title {
        font-size: 24px;
    }

    .login-popup button.btn-close {
        position: absolute;
        font-size: 14px;
        right: 25px;
        cursor: pointer;
        top: 24px;
    }

    .redemption-options h6 {
        font-size: 14px;
    }

    .redem-top-info p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        margin-bottom: 8px;
    }

    .filter-area-top .css-13cymwt-control,
    .css-b62m3t-container,
    .filter-area-top.select-prediction .css-b62m3t-container,
    .filter-area-top.select-prediction .css-13cymwt-control {
        width: 95px;
    }

    .redemption-options ul li {
        padding: 7px;
    }

    .redem-inner-area h2 {
        font-size: 23px;
        margin-bottom: 12px;
    }

    .redem-points-area.folio-amount-area {
        padding: 20px 10px;
    }

    .prediction-content-outer {
        width: 100%;
        max-height: 300px;
        overflow: auto;
        padding-right: 10px;
    }

    /* .folio-amount-area button {
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        background: #fd3f75 !important;
        color: #fff !important;
        font-size: 12px;
        font-weight: 500;
        padding: 3px 1px;
        border-radius: 8px;
        margin: 0 5px 0 0;
        white-space: nowrap;
    } */
}

@media (max-width: 400px) {

    .nft-types-popup .modal-body ul li,
    .nft-types-popup .modal-body ul li:last-child {
        width: 100% !important;
    }

    /* .follow-steps-ad-mid {
        display: none;
    } */
    .explore-top-filter {
        flex-wrap: unset;
    }

}